<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">CRUD Service</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>
      <p class="section-lead">
        Examples and usage guidelines for form control styles, layout options,
        and custom components for creating a wide variety of forms.
      </p>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="submit">
              <div class="card-body">
                    <select-input :value.sync="form.role_id" label="Role"></select-input>
                    <div class="row">
                        <select-input :value.sync="form.menu_id" classes="form-group col-6" label="Menu" @change="getMenuChild"></select-input>
                        <select-input :value.sync="form.menu_child_id" classes="form-group col-6" label="Menu Child"></select-input>
                    </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit">Submit</button>
                <button class="btn btn-secondary" type="reset" @click="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CrudServiceRepository, RoleRepository } from "../../repositories/RepositoryFactory";
export default {
  data() {
    return {
      name: 'CrudServiceCreate',
      form: {
        role_id: null,
        menu_child_id: null,
      },
      data: {
        menus: [],
        menuChilds: [],
        roles: [],
      },
    };
  },
  methods: {
    submit() {
      CrudServiceRepository.create(this.form).then(({ data }) => {
        console.log(data)
        this.$noty.success("Data berhasil ditambahkan");
        this.$router.back();
      });
    },
    getRoles() {
        RoleRepository.get().then(({data}) => {
            console.log(data)
        })
    },
    reset() {
      Object.keys(this.form).forEach((e) => {
        this.form[e] = null;
      });
    },
  },
};
</script>